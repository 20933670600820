import { motion } from "framer-motion";
import { Flex, Box } from "rebass";
import { observer } from "mobx-react-lite";

// Internal dependencies
import { useStores } from "../store/useStores";

import { Title } from "../components/Title";
import { SplitBodyText } from "../components/SplitBodyText";
import { ZKBLogoLink } from "../components/ZKBLogoLink";

// Animation Variant
import { SinglePageVariants } from "../animations/Variants";

interface FlyerProps {
	path: string;
	url: string;
	isExact: boolean;
	params: {
		id: string;
	};
}

export const Flyer = observer(({ match }: { match: FlyerProps }) => {
	const { ProjectionStore } = useStores();

	const [FlyerData] = ProjectionStore?.events.filter(d => Number(d?.id) === Number(match.params.id));

	const titleLength = FlyerData?.title?.length as number;
	const isLongTitle = titleLength >= 15;

	const isPortraitMode = ProjectionStore.deviceOrientation === "portrait";

	if (!FlyerData?.id) return null;

	return (
		<motion.div initial='exit' animate='enter' exit='exit' key={`Flyer.${FlyerData.id}`}>
			<Flex
				id="flyer"
				flexDirection='column'
				alignItems='flex-start'
				p={isPortraitMode ? '5vh 2.5vh' : '5vh'}
				maxHeight={"100vh"}
				maxWidth={"1000px"}
				margin={"0 auto"}
				overflow="hidden"
			>
				<Title
					title={FlyerData?.title}
					color={
						FlyerData?.options?.color ? FlyerData?.options.color : "#E1C26B"
					}
					sx={isPortraitMode ? {
						fontSize: FlyerData?.media ? "6vh" : "10vh",
						padding: FlyerData?.media ? ".25vh 1vh" : "0 2vh",
					} : {
						fontSize: FlyerData?.media ? "10vh" : "20vh",
						padding: FlyerData?.media ? "0 1vh" : "0 2vh",
					}}
				/>

				{FlyerData?.media && (
					<Box
						sx={{
							height: ["60vh"],
							maxHeight: isLongTitle ? '50vh' : '60vh',
							maxWidth: '85vw',
							position: "relative",
							zIndex: 1,
							mt: "-0.01vh",
							mb: "0.2vh",
							lineHeight: 1,
							"& img": {
								height: "100%"
							}
						}}>
						<motion.img
							loading="lazy"
							className="loading-container"
							variants={SinglePageVariants.imageVariants}
							src={FlyerData.media}
							alt={FlyerData?.title}
							style={{
								objectFit: 'cover',
								maxWidth: '100%',
								background: `linear-gradient(to right, #1A1919 8%, ${FlyerData?.options?.color || "#eee"} 38%, #1A1919 54%)`,
							}}
						/>
						{ FlyerData?.mediaCredits && (
							<motion.div variants={SinglePageVariants.imageVariants}>
								<Box sx={{
									position: 'absolute',
									bottom: '10px',
									right: '10px',
									color: '#fff',
									textShadow: '0 0 1px #000',
									fontSize: 'clamp(12px, 1vh, 20px)',
								}}>
									{ FlyerData.mediaCredits }
								</Box>
							</motion.div>
						) }
					</Box>
				)}

				<motion.div variants={SinglePageVariants.headlineVariants}>
					<SplitBodyText
						fontWeight={400}
						maxWidth="70vw"
						color={
							FlyerData?.options?.color ? FlyerData?.options.color : "#E1C26B"
						}
						text={FlyerData?.date as string}
					/>
					<SplitBodyText
						maxWidth="70vw"
						color={
							FlyerData?.options?.color ? FlyerData?.options.color : "#E1C26B"
						}
						text={"Kaufleuten"}
					/>
					<SplitBodyText
						maxWidth={ isPortraitMode ? "70vw" : ["40vw", "50vw", "60vw"]}
						fontWeight={400}
						color={
							FlyerData?.options?.color ? FlyerData?.options.color : "#E1C26B"
						}
						text={FlyerData?.text as string}
					/>
				</motion.div>

				<ZKBLogoLink />
			</Flex>
		</motion.div>
	);
});
